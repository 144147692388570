import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    
      <span className='span1'>
        Copyright: 2023 Grzegorz Bojda. All rights reserved
        </span>
    
  )
}

export default Footer